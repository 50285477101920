import {TicketWithFormattedSalePeriod} from '@wix/wix-events-commons-statics'
import React from 'react'
import {SaleEndedBadge} from '../../../fields/sale-ended-badge'
import {SoldOutBadge} from '../../../fields/sold-out-badge'
import {TicketPrice} from '../../../fields/ticket-price'
import {TicketQuantity} from '../../../fields/ticket-quantity'
import {PricingOptions} from '../../../pricing-options'
import {PriceQuantityContainer} from './price-quantity-container'

export const PriceQuantitySection = ({
  ticket,
  hideErrorMessage,
}: {
  ticket: TicketWithFormattedSalePeriod
  hideErrorMessage: () => void
}) => {
  return (
    <>
      <PriceQuantityContainer
        left={<TicketPrice ticket={ticket} />}
        right={
          <>
            <SoldOutBadge ticket={ticket} />
            <SaleEndedBadge ticket={ticket} />
            <TicketQuantity ticket={ticket} hideErrorMessage={hideErrorMessage} />
          </>
        }
      />
      <PricingOptions ticket={ticket} />
    </>
  )
}
