import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import React, {useEffect, useState} from 'react'
import {useAppSelector} from '../../../../hooks/runtime'
import {getTickets} from '../../../../selectors/tickets'
import {ErrorSection} from '../error-section'
import {Footer} from '../footer'
import {Header} from '../header'
import {TicketsContainer} from '../tickets-container'
import {useCheckoutUnavailableCheck} from '../use-checkout-unavailable-check'
import {DesktopTicket} from './desktop-ticket'
import s from './desktop-tickets-picker.scss'

export const DesktopTicketsPicker = () => {
  useCheckoutUnavailableCheck()

  const tickets = useAppSelector(getTickets)
  const [ticketSelectionError, setTicketSelectionError] = useState(false)
  const {experiments} = useExperiments()

  useEffect(() => {
    setTicketSelectionError(false)
  }, [tickets])

  if (!tickets.length) {
    return null
  }

  return (
    <div data-hook={DH.TICKETS_PICKER} id={DH.TICKETS_PICKER}>
      <Header />
      <TicketsContainer>
        {tickets.map(ticket => (
          <DesktopTicket key={ticket.id} ticket={ticket} hideErrorMessage={() => setTicketSelectionError(false)} />
        ))}
      </TicketsContainer>
      {experiments.enabled(ExperimentNames.DisabledButtonsViewer) && ticketSelectionError && (
        <ErrorSection className={s.notificationWrapper} />
      )}
      <div className={s.footerWrapper}>
        <div className={s.footerContainer}>
          <Footer onTicketSelectionError={() => setTicketSelectionError(true)} />
        </div>
      </div>
    </div>
  )
}
